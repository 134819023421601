import React, { useEffect, useState } from 'react';

import { ChevronDown, Edit, Save } from '@carbon/icons-react';
import { Button, DividerV2, Text } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import Popover from 'components/Popover/Popover';

import { CustomHierarchyFilterMenuV2 } from 'app/components/TerritoryMap/CustomHierarchyFilterMenuV2';
import SegmentPicker from 'app/components/TerritoryMap/TerritoryMapGrid/SegmentPicker';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { SegmentFilter } from 'app/graphql/generated/graphqlApolloTypes';

import { CollectionFilter, defaultCustomHierarchyFilter, NamedHierarchy, SegmentEditorDialogOptions } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './MapGridFilterV2.module.pcss';

const b = block(style);

interface MapGridFilterProps {
  customHierarchies: NamedHierarchy[];
  customHierarchyFilter: CollectionFilter<number>;
  segments: SegmentFilter[];
}
const MapGridFilterV2: React.FC<MapGridFilterProps> = ({ customHierarchies, customHierarchyFilter, segments }) => {
  const [isMapGridFilterOpen, setIsMapGridFilterOpen] = useState(false);
  const { selectedSegmentId, setSegmentEditorDialogOptions, setSelectedSegmentId } = useDedicatedMapProvider();
  const [currentFilter, setCurrentFilter] = useState<CollectionFilter<number>>(customHierarchyFilter);
  const postMessage = useMapWorkerPostMessage();

  useEffect(() => {
    setCurrentFilter(customHierarchyFilter);
  }, [customHierarchyFilter]);

  const toggleSegmentEditor = (option: SegmentEditorDialogOptions.CREATE | SegmentEditorDialogOptions.EDIT) => {
    setSegmentEditorDialogOptions(option);
    setIsMapGridFilterOpen(false);
  };

  const clearFilter = () => {
    const segmentId = null;
    setCurrentFilter(defaultCustomHierarchyFilter);
    setSelectedSegmentId(segmentId);
    applyFilter(defaultCustomHierarchyFilter, null);
  };

  const applyFilter = (filter: CollectionFilter<number>, segmentId) => {
    setSelectedSegmentId(segmentId);
    postMessage({ type: 'custom-hierarchy-filter-change', filter: { ...customHierarchyFilter, ...filter } });
  };

  const filterButtonText =
    segments.find((segment) => segment.segmentId === selectedSegmentId)?.segmentName || formatMessage('HIERARCHIES');

  return (
    <div>
      <Popover
        onInteraction={(nextOpenState) => {
          if (!nextOpenState) setIsMapGridFilterOpen(false);
        }}
        isOpen={isMapGridFilterOpen}
        onClosed={() => setIsMapGridFilterOpen(false)}
        onOpened={() => setIsMapGridFilterOpen(true)}
        content={
          <div className={b('filterSelectionWrapper')}>
            <Text className={b('filterMapsTitle')}>{formatMessage('FILTER_MAPS_BY')}</Text>
            <SegmentPicker
              selectedSegmentId={selectedSegmentId}
              onUpdateSegment={(filter, id) => {
                setCurrentFilter(filter);
                setSelectedSegmentId(id);
                applyFilter(filter, id);
              }}
              segments={segments}
            />
            {selectedSegmentId === null && (
              <>
                <DividerV2 />
                <div className={b('customHierarchyFilterMenuWrapper')}>
                  <CustomHierarchyFilterMenuV2
                    filter={currentFilter}
                    hierarchies={customHierarchies}
                    onUpdateFilter={(filter) => setCurrentFilter(filter)}
                  />
                </div>
              </>
            )}
            <div className={b('filterFooter')}>
              <div className={b('saveSegment')}>
                {selectedSegmentId ? (
                  <IconButton
                    type="button"
                    minimal
                    testId="edit-segment-button"
                    icon={<Edit />}
                    onClick={() => {
                      toggleSegmentEditor(SegmentEditorDialogOptions.EDIT);
                    }}
                    tooltipText={formatMessage('EDIT_SEGMENT')}
                    tooltipPlacement="bottom"
                  />
                ) : (
                  <IconButton
                    type="button"
                    minimal
                    testId="save-segment-button"
                    icon={<Save />}
                    onClick={() => {
                      toggleSegmentEditor(SegmentEditorDialogOptions.CREATE);
                    }}
                    tooltipText={formatMessage('SAVE_SEGMENT')}
                    tooltipPlacement="bottom"
                  />
                )}
              </div>
              <div className={b('filterApplyAndClear')}>
                <Button
                  minimal
                  text={formatMessage('CLEAR_ALL')}
                  onClick={clearFilter}
                  data-testid="clear-filter-button"
                />
                <Button
                  className={b('applyFilterButton')}
                  text={formatMessage('APPLY')}
                  onClick={() => applyFilter(currentFilter, selectedSegmentId)}
                  data-testid="apply-filter-button"
                />
              </div>
            </div>
          </div>
        }
        minimal
        placement="bottom-end"
      >
        <TextButton
          text={filterButtonText}
          type="button"
          minimal
          large={false}
          rightIcon={<ChevronDown />}
          testId="filter-button"
          onClick={() => setIsMapGridFilterOpen((prevIsMapGridFilterOpen) => !prevIsMapGridFilterOpen)}
          active={isMapGridFilterOpen}
        />
      </Popover>
    </div>
  );
};

export default MapGridFilterV2;
