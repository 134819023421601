import React from 'react';

import { AddAlt, TrashCan } from '@carbon/icons-react';
import { Intent, Text } from '@varicent/components';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

import TextButton from 'components/Buttons/TextButton/TextButton';
import Dialog from 'components/Dialog/Dialog';

import FormCheckbox from 'app/components/FormFields/FormCheckbox/FormCheckbox';
import FormTextInputGroup from 'app/components/FormFields/FormTextInputGroup/FormTextInputGroup';
import { CustomHierarchyFilterMenuV2 } from 'app/components/TerritoryMap/CustomHierarchyFilterMenuV2';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { DefinitionFilterOperatorEnum } from 'app/graphql/generated/graphqlApolloTypes';
import { useUpsertSegment } from 'app/graphql/mutations/upsertSegment';

import useShowToast from 'app/hooks/useShowToast';

import { CollectionFilter, HierarchyType, MchQuantity, NamedHierarchy } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SegmentEditorDialog.module.pcss';

const b = block(style);

interface SegmentEditorDialogProps {
  onClose: () => void;
  hierarchies: NamedHierarchy[];
  filter: CollectionFilter<number>;
  segmentName: string;
  isEditMode: boolean;
  refetchSegments: () => void;
}

interface SegmentEditorFormValues {
  segmentName: string;
  filter: CollectionFilter<number>;
  applySegment: boolean;
}

const SegmentEditorDialog: React.FC<SegmentEditorDialogProps> = ({
  onClose,
  hierarchies,
  filter,
  segmentName,
  isEditMode,
  refetchSegments
}) => {
  const initialFormValues: SegmentEditorFormValues = { segmentName, filter: null, applySegment: false };
  const { selectedBattleCardId } = useMapContextRedistributor();
  const { chosenCustomHierarchy, setSelectedSegmentId } = useDedicatedMapProvider();

  const validationSchema = yup.object().shape({
    segmentName: yup.string().required(formatMessage('REQUIRED_FIELD'))
  });

  const showToast = useShowToast();

  const postMessage = useMapWorkerPostMessage();

  const [upsertSegment] = useUpsertSegment({
    onError: () => {
      showToast(isEditMode ? formatMessage('SEGMENT_EDIT_ERROR') : formatMessage('SEGMENT_CREATE_ERROR'), 'danger');
    }
  });

  return (
    <Dialog
      isOpen={true}
      size="small"
      title={isEditMode ? formatMessage('EDIT_SEGMENT') : formatMessage('CREATE_SEGMENT')}
      showDialogFooter={false}
    >
      <div className={b()}>
        <Text>{formatMessage('CREATE_SEGMENT_DIALOG_DESCRIPTION')}</Text>
        <Formik<SegmentEditorFormValues>
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            let rootHierarchyId;
            let hierarchyType;

            const currentFilter = values.filter || filter;
            const ids = currentFilter.ids as unknown;
            const operator = currentFilter.kind as unknown;

            if (chosenCustomHierarchy.quantity === MchQuantity.SINGULAR) {
              rootHierarchyId = chosenCustomHierarchy.details.rootHierarchyId;
              hierarchyType = HierarchyType.CustomHierarchy;
            } else {
              throw new Error('Only singular custom hierarchies are supported');
            }

            await upsertSegment({
              variables: {
                input: {
                  segmentId: null,
                  segmentName: values.segmentName,
                  battlecardId: parseInt(selectedBattleCardId),
                  clauses: [
                    {
                      rootHierarchyId,
                      hierarchyType,
                      ids: ids as number[],
                      operator: operator as DefinitionFilterOperatorEnum
                    }
                  ]
                }
              },
              onCompleted: (result) => {
                if (values.applySegment) {
                  setSelectedSegmentId(result.upsertSegment?.segmentId);
                  postMessage({ type: 'custom-hierarchy-filter-change', filter: currentFilter });
                }
                refetchSegments();
                onClose();
              }
            });
          }}
        >
          {({ isSubmitting, handleSubmit, setFieldValue }) => (
            <>
              <Field
                className={b('segmentNameField')}
                name="segmentName"
                component={FormTextInputGroup}
                label={formatMessage('SEGMENT_NAME')}
              />
              <div className={b('customHierarchyFilterMenuContainer')}>
                <Field
                  name="filter"
                  component={CustomHierarchyFilterMenuV2}
                  showFooter={false}
                  hierarchies={hierarchies}
                  filter={filter}
                  onUpdateFilter={(filter) => setFieldValue('filter', filter)}
                />
              </div>
              <div className={b('applyCheckboxField')}>
                <Field
                  name="applySegment"
                  component={FormCheckbox}
                  labelElement={<span className={b('applySegmentLabel')}>{formatMessage('APPLY_SEGMENT')}</span>}
                  type="checkbox"
                />
              </div>
              <div className={b('footer')}>
                <div>
                  <TextButton
                    minimal
                    intent={Intent.DANGER}
                    type="button"
                    testId="delete-button"
                    text={formatMessage('DELETE')}
                    icon={<TrashCan />}
                  />
                </div>
                <div>
                  <TextButton
                    className={b('rightActionButtons')}
                    type="button"
                    testId="cancel-button"
                    text={formatMessage('CANCEL')}
                    onClick={() => onClose()}
                    minimal
                  />
                  <TextButton
                    className={b('rightActionButtons')}
                    intent={Intent.PRIMARY}
                    type="button"
                    testId="create-button"
                    text={isEditMode ? formatMessage('CONFIRM') : formatMessage('CREATE')}
                    icon={<AddAlt />}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};

export default SegmentEditorDialog;
