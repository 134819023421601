import React, { useEffect, useMemo } from 'react';

import { Text } from '@varicent/components';

import { useMapQuestion } from 'app/components/TerritoryMap/hooks/useMapQuestion';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { SegmentFilter } from 'app/graphql/generated/graphqlApolloTypes';

import { MchQuantity, NamedHierarchy } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import SegmentCard from './SegmentCard';
import style from './SegmentCards.module.pcss';

const b = block(style);

interface SegmentCardsProps {
  segments: SegmentFilter[];
  customHierarchies: NamedHierarchy[];
  territoryRules: Array<unknown>; // We don't need any details, just the identity of the object
}

const SegmentCards: React.FC<SegmentCardsProps> = ({ segments, customHierarchies, territoryRules }) => {
  const { chosenCustomHierarchy } = useDedicatedMapProvider();
  const terCountForSegments = useMapQuestion('get-territory-counts-for-segments');

  const visibleSegments = useMemo(
    () =>
      segments.filter(
        (segment) =>
          chosenCustomHierarchy.quantity === MchQuantity.SINGULAR &&
          segment.clauses.length === 1 &&
          segment.clauses[0].rootHierarchyId === chosenCustomHierarchy.details.rootHierarchyId
      ),
    [segments, chosenCustomHierarchy]
  );

  useEffect(() => {
    terCountForSegments.ask();
  }, [segments, territoryRules]);

  return (
    <div className={b()}>
      <Text className={b('title')}>{formatMessage('SELECT_A_SEGMENT')}</Text>
      {visibleSegments.map((segment) => (
        <SegmentCard
          territoriesCount={terCountForSegments.answer?.get(segment.segmentId) ?? null}
          isLoadingTerritoriesCount={terCountForSegments.isLoading}
          name={segment.segmentName}
          key={segment.segmentId}
          id={segment.segmentId}
          filter={segment.clauses}
          customHierarchies={customHierarchies}
        />
      ))}
    </div>
  );
};

export default SegmentCards;
