import { useEffect } from 'react';

import { useMapContextRedistributor } from 'app/contexts/MapContextRedistributor/mapContextRedistributorProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { useGetSegments } from 'app/graphql/queries/getSegments';

export const useSegments = () => {
  const { selectedBattleCardId, selectedDeploymentModelId } = useMapContextRedistributor();

  const postMessage = useMapWorkerPostMessage();

  const { data, refetch } = useGetSegments({
    fetchPolicy: 'no-cache',
    variables: {
      battlecardId: parseInt(selectedBattleCardId),
      deploymentModelId: selectedDeploymentModelId
    }
  });

  useEffect(() => {
    const segments = data?.getDeploymentModelSpec?.battlecards[0]?.segments ?? [];

    postMessage({
      type: 'segments-change',
      segments
    });
  }, [data]);

  return {
    refetchSegments: refetch
  };
};
