import React, { useState } from 'react';

import { ChevronDown, AddAlt } from '@carbon/icons-react';
import { Button, DividerV2, Intent } from '@varicent/components';

import { Menu } from 'components/menu/Menu';
import { MenuItem } from 'components/menu/MenuItem';
import Popover from 'components/Popover/Popover';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';

import { SegmentFilter } from 'app/graphql/generated/graphqlApolloTypes';

import {
  CollectionFilter,
  CollectionFilterKind,
  defaultCustomHierarchyFilter,
  MchQuantity,
  SegmentEditorDialogOptions
} from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SegmentPicker.module.pcss';

const b = block(style);

interface SegmentPickerProps {
  onUpdateSegment: (filter: CollectionFilter<number>, id: number) => void;
  selectedSegmentId: number;
  segments: SegmentFilter[];
}

const SegmentPicker: React.FC<SegmentPickerProps> = ({ onUpdateSegment, selectedSegmentId, segments }) => {
  const [isSegmentPickerOpen, setIsSegmentPickerOpen] = useState(false);
  const { setSegmentEditorDialogOptions, chosenCustomHierarchy } = useDedicatedMapProvider();

  const visibleSegments = segments.filter(
    (segment) =>
      chosenCustomHierarchy.quantity === MchQuantity.SINGULAR &&
      segment.clauses[0].rootHierarchyId === chosenCustomHierarchy.details.rootHierarchyId
  );

  const dropdownButtonText =
    visibleSegments.find((segment) => segment.segmentId === selectedSegmentId)?.segmentName ||
    formatMessage('HIERARCHIES');

  return (
    <div className={b()}>
      <Popover
        isOpen={isSegmentPickerOpen}
        usePortal={false}
        className={b('segmentSelectionDropdown')}
        content={
          <Menu
            onClick={(e) => e.stopPropagation()}
            className={b('segmentSelectionMenu')}
            data-testid="segment-selection-menu"
          >
            {visibleSegments.length > 0 && (
              <>
                <MenuItem
                  onClick={() => {
                    onUpdateSegment(defaultCustomHierarchyFilter, null);
                    setIsSegmentPickerOpen(false);
                  }}
                  text={formatMessage('HIERARCHIES')}
                  data-testid="hierarchies-option"
                />
                <DividerV2 className={b('divider')} />
                <MenuItem
                  text={formatMessage('SEGMENTS')}
                  data-testid="segment-menu-label"
                  className={b('segmentMenuItemLabel')}
                />
                {visibleSegments.map((segment) => (
                  <MenuItem
                    onClick={() => {
                      const filter: CollectionFilter<number> = {
                        kind: segment.clauses[0].operator as unknown as CollectionFilterKind,
                        ids: segment.clauses[0].hierarchies.map((h) => h.hierarchyId)
                      };
                      onUpdateSegment(filter, segment.segmentId);
                      setIsSegmentPickerOpen(false);
                    }}
                    key={segment.segmentId}
                    text={segment.segmentName}
                    data-testid={`segment-${segment.segmentId}`}
                  />
                ))}
                <DividerV2 className={b('divider')} />
              </>
            )}
            <div className={b('createSegmentButtonWrapper')}>
              <Button
                onClick={() => setSegmentEditorDialogOptions(SegmentEditorDialogOptions.CREATE)}
                className={b('createSegmentButton')}
                text={formatMessage('CREATE_SEGMENT')}
                icon={AddAlt}
              />
            </div>
          </Menu>
        }
        minimal
        placement="bottom-start"
      >
        <Button
          rightIcon={ChevronDown}
          text={dropdownButtonText}
          type="button"
          onClick={() => setIsSegmentPickerOpen(!isSegmentPickerOpen)}
          intent={Intent.NONE}
          data-testid="segment-dropdown-button"
          className={b('segmentDropdownButton')}
        />
      </Popover>
    </div>
  );
};

export default SegmentPicker;
