import booleanIntersects from '@turf/boolean-intersects';
import { Geometry } from '@turf/helpers';
import { PrimaryHierarchyTypeForMap } from 'app/workers/MapWorker/MapWorkerProtocol';
import { LngLatBounds, Map as MapboxMap, MapboxGeoJSONFeature, PointLike, RequestParameters } from 'mapbox-gl';

import { SearchableSelectMenuItem } from 'components/models';

import { MapStyleTokens } from 'app/components/TerritoryMap/MapStyleTokens';
import { isPolygonExpression } from 'app/components/TerritoryMap/PolygonLayerHelpers';

import { GeoJsonTypes, MAP_PROXY_PATH } from 'app/global/variables';

import {
  GetTerritoryRulesForAccountMove_getTerritoryRules,
  GetTerritoryRulesForMap_getTerritoryRules_territoryRules,
  NewRuleDefinitionResponse,
  RuleIncExc,
  RuleIncExcGroup,
  TargetRuleComponent
} from 'app/graphql/generated/graphqlApolloTypes';

import {
  CustomerFeatureCollection,
  CustomerInsight,
  CustomerForMap,
  LassoAbleGeometry,
  LassoPolygon,
  MapSelectionOptions,
  MapSelectionRestriction,
  MapSelectionTarget,
  MapSelectionTool,
  PinIcon,
  RootHierarchyField,
  RuleForMap,
  SelectedGeography,
  AccountTypeFilterOptions,
  AccountTerritoryFilterOptions,
  AccountFilter,
  RuleWithAttachedDecompressedDefinition,
  SimplifiedRuleWithDefinition,
  TerritoryColors,
  NamedHierarchy,
  MapCustomHierarchySetting,
  MchQuantity,
  StructuredCloneableFeature,
  CustomerTooltipProperties,
  PolygonTooltipProperties,
  CollectionFilter,
  CollectionFilterKind,
  SourceGroup,
  HierarchyType
} from 'app/models';

import { config } from 'utils/config';
import { LeastUsedColorPicker } from 'utils/LeastUsedColorPicker';
import { formatMessage } from 'utils/messages/utils';

import mapHatch from 'assets/pngs/mapHatch/Stripe-Pattern.png';
import mapPinFavorite from 'assets/pngs/mapPins/map-pin-favorite.png';
import mapPinFlag from 'assets/pngs/mapPins/map-pin-flag.png';
import mapPinLocation from 'assets/pngs/mapPins/map-pin-location.png';
import mapPinPin from 'assets/pngs/mapPins/map-pin-pin.png';
import mapPinSquare from 'assets/pngs/mapPins/map-pin-square.png';
import mapPinStar from 'assets/pngs/mapPins/map-pin-star.png';
import mapPinUser from 'assets/pngs/mapPins/map-pin-user.png';

import { startPerformanceTimer } from './analyticsReporter';
import { CompressionUtil } from './CompressionUtil';
import { asStructuredFeature } from './mapDataUtils';

const createRuleIncExc = (hierarchyIds: Iterable<number>, hierarchyType: RootHierarchyField): RuleIncExc => {
  const output: RuleIncExc = {
    contains: [],
    hierarchyType
  };
  for (const hierarchyId of hierarchyIds) {
    output.contains.push({
      hierarchyId,
      name: null
    });
  }
  return output;
};

export const generateRuleDefinition = (input: {
  // Base
  geoIds?: number[];
  accountIds?: number[];
  customHierarchyIds?: number[];
  // Overrides
  accountOverrideIds?: number[];
  // Exclusions
  excludedCustomHierarchyIds?: number[];
}): NewRuleDefinitionResponse => {
  const ruleDefinition: NewRuleDefinitionResponse = {
    base: {
      inclusions: [],
      exclusions: []
    },
    modifiers: {
      inclusions: []
    }
  };

  if (input.geoIds?.length > 0) {
    ruleDefinition.base.inclusions.push(
      createRuleIncExc(input.geoIds, RootHierarchyField.GeographicTerritoryHierarchyField)
    );
  }

  if (input.accountIds?.length > 0) {
    ruleDefinition.base.inclusions.push(
      createRuleIncExc(input.accountIds, RootHierarchyField.CustomerAccountHierarchyField)
    );
  }

  if (input.customHierarchyIds?.length > 0) {
    ruleDefinition.base.inclusions.push(
      createRuleIncExc(input.customHierarchyIds, RootHierarchyField.CustomHierarchyField)
    );
  }

  if (input.excludedCustomHierarchyIds?.length > 0) {
    ruleDefinition.base.exclusions.push(
      createRuleIncExc(input.excludedCustomHierarchyIds, RootHierarchyField.CustomHierarchyField)
    );
  }

  if (input.accountOverrideIds?.length > 0) {
    ruleDefinition.modifiers.inclusions.push(
      createRuleIncExc(input.accountOverrideIds, RootHierarchyField.CustomerAccountHierarchyField)
    );
  }

  return ruleDefinition;
};

const isLassoIntersecting = (polygonGeometry: Geometry, lassoGeometry: Geometry) => {
  try {
    return booleanIntersects(polygonGeometry, lassoGeometry);
  } catch {
    return false;
  }
};

export const isGeometryWithinLasso = (geometry: LassoAbleGeometry, lassoPolygon: LassoPolygon): boolean => {
  switch (geometry.type) {
    case GeoJsonTypes.Point:
    case GeoJsonTypes.Polygon:
      return isLassoIntersecting(geometry, lassoPolygon.geometry);
    case GeoJsonTypes.MultiPolygon:
      return geometry.coordinates.some((coordinate) => {
        const singlePolygon = {
          type: GeoJsonTypes.Polygon,
          coordinates: coordinate
        };
        return isLassoIntersecting(singlePolygon, lassoPolygon.geometry);
      });
    default:
      return false;
  }
};

const getBoundingBox = (coordinates: Array<number[]>) => {
  let minLon = Infinity;
  let minLat = Infinity;
  let maxLon = -Infinity;
  let maxLat = -Infinity;
  for (const [lon, lat] of coordinates) {
    minLon = Math.min(minLon, lon);
    minLat = Math.min(minLat, lat);
    maxLon = Math.max(maxLon, lon);
    maxLat = Math.max(maxLat, lat);
  }
  return {
    minLon,
    minLat,
    maxLon,
    maxLat
  };
};

export const getLassoBounds = (lassoPolygon: LassoPolygon) => getBoundingBox(lassoPolygon.geometry.coordinates[0]);

export const isMappableCustom = (chosenCustomHierarchy: MapCustomHierarchySetting, inc: RuleIncExc) => {
  switch (chosenCustomHierarchy.quantity) {
    case MchQuantity.NONE:
      return false;
    case MchQuantity.ALL:
      return true;
    case MchQuantity.SINGULAR:
      return inc.rootHierarchyId === chosenCustomHierarchy.details.rootHierarchyId;
    default:
      throw new Error(
        `Unexpected chosen custom hierarchy quantity "${(chosenCustomHierarchy as MapCustomHierarchySetting).quantity}"`
      );
  }
};

export const extractMappableElements = (
  { base, modifiers }: NewRuleDefinitionResponse,
  isMappableGeo: (geoId: number) => boolean,
  chosenCustomHierarchy: MapCustomHierarchySetting
): {
  geoHierarchyIds: Set<number>;
  accountHierarchyIds: Set<number>;
  customHierarchies: Map<number, NamedHierarchy>;
  excludedCustomHierarchies?: Set<number>;
} => {
  const excludedCustomHierarchies = new Set<number>();
  for (const exclusion of base?.exclusions ?? []) {
    if (exclusion.hierarchyType !== RootHierarchyField.CustomHierarchyField) return null;

    if (!isMappableCustom(chosenCustomHierarchy, exclusion)) return null;
    for (const custom of exclusion.contains) excludedCustomHierarchies.add(custom.hierarchyId);
  }

  const geoHierarchyIds = new Set<number>();
  const customHierarchies = new Map<number, NamedHierarchy>();

  for (const baseInc of base?.inclusions ?? []) {
    switch (baseInc.hierarchyType) {
      case RootHierarchyField.CustomHierarchyField:
        if (!isMappableCustom(chosenCustomHierarchy, baseInc)) return null;
        for (const custom of baseInc.contains) customHierarchies.set(custom.hierarchyId, custom as NamedHierarchy);
        break;
      case RootHierarchyField.GeographicTerritoryHierarchyField:
        for (const { hierarchyId } of baseInc.contains) {
          if (isMappableGeo(hierarchyId)) geoHierarchyIds.add(hierarchyId);
          else return null;
        }
        break;
      default:
        return null;
    }
  }

  const accountHierarchyIds = new Set<number>();

  for (const modInc of modifiers?.inclusions ?? []) {
    if (modInc.hierarchyType !== RootHierarchyField.CustomerAccountHierarchyField) return null;
    for (const { hierarchyId } of modInc.contains) accountHierarchyIds.add(hierarchyId);
  }

  return { geoHierarchyIds, accountHierarchyIds, customHierarchies, excludedCustomHierarchies };
};

export const attachDecompressedRuleDefinition = (
  rule: GetTerritoryRulesForMap_getTerritoryRules_territoryRules
): RuleWithAttachedDecompressedDefinition => ({
  definition: CompressionUtil.decompress(rule.ruleDefinitionSlimCompressed),
  compressedDefinition: rule.ruleDefinitionSlimCompressed,
  rule
});

export const extractReservedAccountHierarchyIds = (modifiers: RuleIncExcGroup): Set<number> => {
  const reservedAccountHierarchyIds = new Set<number>();
  for (const modInc of modifiers?.inclusions ?? []) {
    if (modInc.hierarchyType === RootHierarchyField.CustomerAccountHierarchyField) {
      for (const { hierarchyId } of modInc.contains) reservedAccountHierarchyIds.add(hierarchyId);
    }
  }
  return reservedAccountHierarchyIds;
};

export const formatSimplifiedRuleForMap = (
  { rule, definition }: RuleWithAttachedDecompressedDefinition,
  isMappableGeo: (geoId: number) => boolean,
  chosenCustomHierarchy: MapCustomHierarchySetting,
  checkIsPassingFilter: (customs: Map<number, NamedHierarchy> | undefined) => boolean
): SimplifiedRuleWithDefinition => {
  const {
    ruleId,
    territoryId,
    color,
    territoryGroupId,
    territoryName,
    measureValue,
    numberOfAccounts,
    effectiveDate,
    endDate
  } = rule;
  const mappableElements = extractMappableElements(definition, isMappableGeo, chosenCustomHierarchy);
  const isMappable = mappableElements !== null;
  const isEmpty = isMappable && mappableElements.geoHierarchyIds.size === 0;

  const isPassingFilter = checkIsPassingFilter(mappableElements?.customHierarchies);

  let reservedAccountHierarchyIds = new Set<number>();
  if (isMappable && !isPassingFilter) {
    reservedAccountHierarchyIds = mappableElements.accountHierarchyIds;
  } else if (!isMappable || !isPassingFilter) {
    extractReservedAccountHierarchyIds(definition.modifiers);
  }

  return {
    geoHierarchyIds: mappableElements?.geoHierarchyIds ?? new Set(),
    accountHierarchyIds: mappableElements?.accountHierarchyIds ?? new Set(),
    reservedAccountHierarchyIds,
    customHierarchies: mappableElements?.customHierarchies ?? new Map(),
    excludedCustomHierarchies: mappableElements?.excludedCustomHierarchies ?? new Set(),
    rule: {
      ruleId,
      territoryId,
      territoryName,
      territoryGroupId,
      color,
      isMappable,
      isPassingFilter,
      isEmpty,
      measureValue,
      numberOfAccounts,
      effectiveDate,
      endDate
    }
  };
};

export const areChosenCustomsEqual = (a: MapCustomHierarchySetting, b: MapCustomHierarchySetting) => {
  if (a.quantity !== b.quantity) return false;
  if (a.quantity === MchQuantity.SINGULAR && b.quantity === MchQuantity.SINGULAR) {
    return a.details.rootHierarchyId === b.details.rootHierarchyId;
  }
  return true;
};

export const assignFallbackColors = (
  rules: ReadonlyArray<{ ruleId: number; color?: string }>,
  colorCache: Map<number, TerritoryColors>
) => {
  const colorPicker = new LeastUsedColorPicker([]);
  // First pass, use explicit and cached colors
  for (const rule of rules) {
    if (!rule.color && colorCache.has(rule.ruleId)) {
      rule.color = colorCache.get(rule.ruleId);
    }
    colorPicker.useColor(rule.color);
  }

  // Second pass, use least used color and cache it
  for (const rule of rules) {
    if (rule.color) continue;
    const newColor = colorPicker.useLeastUsedColor();
    rule.color = newColor;
    colorCache.set(rule.ruleId, newColor);
  }
};

export const getTerritoryGroupLevelName = (levelIndex: number | null, isLongFormat?: boolean): string => {
  if (levelIndex == null) return formatMessage('TERRITORIES');
  if (isLongFormat) return formatMessage('TERRITORY_GROUP_LEVEL_WITH_VALUE', { value: levelIndex });
  return formatMessage('LEVEL_WITH_VALUE', { value: levelIndex });
};

export const isSelectionAllowedByOptions = (options: MapSelectionOptions, isAssigned: boolean): boolean => {
  if (options.target === MapSelectionTarget.territories) return isAssigned;
  switch (options.restriction) {
    case MapSelectionRestriction.all:
      return true;
    case MapSelectionRestriction.assigned:
      return isAssigned;
    case MapSelectionRestriction.unassigned:
      return !isAssigned;
    default:
      throw new Error(`Unexpected MapSelectionRestriction ${options.restriction}`);
  }
};

const addImageToMap = async (
  map: MapboxMap,
  imageName: string,
  imageUrl: string,
  options?: { pixelRatio?: number; sdf?: boolean }
) =>
  new Promise<boolean>((resolve, reject) => {
    if (map.hasImage(imageName))
      // Already added
      return resolve(false);

    map.loadImage(imageUrl, (error, image) => {
      if (map.getStyle() && map.hasImage(imageName))
        // Added before this load finished
        return resolve(false);

      if (error) return reject(error);

      map.addImage(imageName, image, options);
      resolve(true);
    });
  });

export const loadMapImages = async (map: MapboxMap): Promise<void> => {
  const pinIcons = [
    { id: PinIcon.STAR, src: mapPinStar },
    { id: PinIcon.SQUARE, src: mapPinSquare },
    { id: PinIcon.USER, src: mapPinUser },
    { id: PinIcon.FLAG, src: mapPinFlag },
    { id: PinIcon.LOCATION, src: mapPinLocation },
    { id: PinIcon.PIN, src: mapPinPin },
    { id: PinIcon.FAVORITE, src: mapPinFavorite }
  ];
  const pinPromises = pinIcons.map(({ id, src }) => {
    const imageName = `${MapStyleTokens.icons.pinPrefix}${id}`;
    return addImageToMap(map, imageName, src, { sdf: true });
  });

  const mapHatchPromise = addImageToMap(map, 'map-hatch', mapHatch, { sdf: true });

  await Promise.all([...pinPromises, mapHatchPromise]);
};

export const transformMapboxRequest = (
  url: string,
  auth0Token: string,
  headers: Record<string, string>
): RequestParameters => {
  const newUrl = new URL(url);

  if (!newUrl.searchParams.has('access_token')) {
    return { url };
  }

  newUrl.searchParams.delete('access_token');

  return {
    url: `${config.API_GATEWAY_ENDPOINT}${MAP_PROXY_PATH}${newUrl.pathname}?${newUrl.searchParams.toString()}`,
    headers: {
      ...headers,
      Authorization: auth0Token
    }
  };
};

export const queryFeaturesInBounds = (
  mapboxMap: Pick<MapboxMap, 'project' | 'queryRenderedFeatures'>,
  lassoPolygon: LassoPolygon,
  layerIds: string[]
): StructuredCloneableFeature[] => {
  const boundingBox = getLassoBounds(lassoPolygon);
  const pixelCoordinates = [
    mapboxMap.project([boundingBox.minLon, boundingBox.minLat]),
    mapboxMap.project([boundingBox.maxLon, boundingBox.maxLat])
  ];

  const polygonsInBounds = mapboxMap.queryRenderedFeatures(pixelCoordinates as [PointLike, PointLike], {
    filter: isPolygonExpression
  });
  const outputFeatures: StructuredCloneableFeature[] = [];

  const lassoableLayerSet = new Set(layerIds);
  polygonsInBounds.forEach((polygon) => {
    if (polygon.id && lassoableLayerSet.has(polygon.layer?.id)) outputFeatures.push(asStructuredFeature(polygon));
  });
  return outputFeatures;
};

export const isTogglePointer = (selectionTool: MapSelectionTool): boolean =>
  selectionTool === MapSelectionTool.togglePointer;

export const isLassoTool = (selectionTool: MapSelectionTool): boolean =>
  selectionTool === MapSelectionTool.addLasso || selectionTool === MapSelectionTool.removeLasso;

export const isLineRulerTool = (selectionTool: MapSelectionTool): boolean =>
  selectionTool === MapSelectionTool.lineRulerTool;

const formatFeature = (customer: CustomerForMap) => ({
  type: 'Feature' as const,
  id: customer.accountId,
  geometry: {
    type: 'Point' as const,
    coordinates: customer.center
  },
  properties: customer
});

export const formatPinsAsFilteredFeatureCollection = (
  customerPins: ReadonlyArray<CustomerForMap>,
  accountFilter: AccountFilter,
  selectedRuleIds: number[] | null,
  ignoredRuleIds: Set<number>,
  canViewUnassigned: boolean
): CustomerFeatureCollection => {
  const timer = startPerformanceTimer('formattingPinsAsFeatures');
  const newAccountPinFeatures: CustomerFeatureCollection = {
    type: 'FeatureCollection',
    features: []
  };
  const selectedRuleIdsSet = new Set(selectedRuleIds);

  customerPins.forEach((customerPin) => {
    const isUnassigned =
      !customerPin.ruleId && !customerPin.isGeoOverassigned && !customerPin.accountOverassignmentCount;
    if (!canViewUnassigned && isUnassigned) return;
    if (ignoredRuleIds.has(customerPin.ruleId)) return;

    switch (accountFilter.territory) {
      case AccountTerritoryFilterOptions.SELECTED_TERRITORIES:
        if (!selectedRuleIdsSet.has(customerPin.ruleId)) return;
        break;
      default:
        // By default, include all
        break;
    }
    switch (accountFilter.override) {
      case AccountTypeFilterOptions.OVERRIDES:
        if (!customerPin.isModifierAccount) return;
        break;
      default:
        // By default, include all
        break;
    }
    newAccountPinFeatures.features.push(formatFeature(customerPin));
  });
  timer.stop({
    originalCount: customerPins.length,
    filteredCount: newAccountPinFeatures.features.length
  });
  return newAccountPinFeatures;
};

export const createInsightFilter = ({
  selectedRuleIds,
  selectedGeoIds,
  selectionTarget,
  selectedAccountIds
}: {
  selectedGeoIds: number[];
  selectedRuleIds: number[];
  selectionTarget: MapSelectionTarget;
  selectedAccountIds: number[];
}): ((insight: CustomerInsight) => boolean) => {
  switch (selectionTarget) {
    case MapSelectionTarget.territories:
      const ruleIdSet = new Set(selectedRuleIds);
      return (insight: CustomerInsight) => ruleIdSet.has(insight.ruleId);
    case MapSelectionTarget.polygons:
      const geoIdSet = new Set(selectedGeoIds);
      return (insight: CustomerInsight) => geoIdSet.has(insight.geographyId);
    case MapSelectionTarget.accounts:
      const accountIdsSet = new Set(selectedAccountIds);
      return (insight: CustomerInsight) => accountIdsSet.has(insight.accountId);
    default:
      return () => false;
  }
};

export const getSelectedExpandedRulesList = ({
  mappableTerritoryRules,
  selectedRuleIds
}: {
  mappableTerritoryRules: RuleForMap[];
  selectedRuleIds: number[];
}): RuleForMap[] => {
  return mappableTerritoryRules.filter((rule) => selectedRuleIds.includes(rule.ruleId));
};

const sortByMeasureValueDesc = (a: { measureValue: number }, b: { measureValue: number }): number =>
  b.measureValue - a.measureValue;

export const createSelectedExpandedGeosList = ({
  selectedGeoIds,
  selectedTotalAccountsList,
  geographyNameMap
}: {
  selectedGeoIds: number[];
  selectedTotalAccountsList: CustomerInsight[];
  geographyNameMap: ReadonlyMap<number, string>;
}): SelectedGeography[] => {
  const measureValueByGeoId = new Map<number, number>();

  selectedTotalAccountsList.forEach((account) => {
    const prevValue = measureValueByGeoId.get(account.geographyId) ?? 0;
    measureValueByGeoId.set(account.geographyId, prevValue + account.measureValue);
  });

  return selectedGeoIds
    .map((geoId) => ({
      geographyName: geographyNameMap.get(geoId),
      measureValue: measureValueByGeoId.get(geoId) ?? 0
    }))
    .sort(sortByMeasureValueDesc);
};

export const formatAccountTooltip = (feature: MapboxGeoJSONFeature): CustomerTooltipProperties => {
  const { properties } = feature;
  return {
    accountName: properties.accountName,
    measureValue: properties.measureValue,
    isModifierAccount: properties.isModifierAccount,
    territoryId: properties.territoryId,
    territoryName: properties.territoryName,
    ruleId: properties.ruleId,
    combinedRuleId: properties.combinedRuleId,
    isGeoOverassigned: properties.isGeoOverassigned,
    geoOverassignmentCount: properties.geoOverassignmentCount,
    accountOverassignmentCount: properties.accountOverassignmentCount,
    isClusterContainsOverassigned: properties.isClusterContainsOverassigned,
    isClusterContainsUnassigned: properties.isClusterContainsUnassigned
  };
};

export const formatGeoTooltip = (feature: MapboxGeoJSONFeature): PolygonTooltipProperties => {
  const { state } = feature;
  return {
    territoryId: state.territoryId,
    territoryName: state.territoryName,
    groupName: state.groupName,
    groupOwner: state.groupOwner,
    isPolygonOverAssigned: state.isOverAssignedGeo ?? false,
    isOverAssignedRule: state.isOverAssignedRule ?? false,
    overAssignmentCount: state.overAssignmentCount ?? 0,
    ruleId: state.ruleId
  };
};

export const getAccountSingularRuleId = (accountTooltip: CustomerTooltipProperties): number => {
  switch (accountTooltip?.combinedRuleId) {
    case 'mixed':
    case 'unassigned':
      return null;
    case undefined:
      return accountTooltip?.ruleId;
    default:
      return parseInt(accountTooltip?.combinedRuleId);
  }
};

export const formatTerritoryMenuItems = ({
  territories,
  shouldReturnDates,
  shouldIncludeUnassignItem,
  shouldIncludeDeleteItem,
  deleteIcon
}: {
  territories: GetTerritoryRulesForAccountMove_getTerritoryRules;
  shouldReturnDates: boolean;
  shouldIncludeUnassignItem: boolean;
  shouldIncludeDeleteItem: boolean;
  deleteIcon: JSX.Element;
}): SearchableSelectMenuItem[] => {
  const unassignedTerritoryItem: SearchableSelectMenuItem = {
    key: formatMessage('UNASSIGNED_TERRITORY'),
    value: null
  };

  const deleteRedirectItem: SearchableSelectMenuItem = {
    key: formatMessage('DELETE_REDIRECT'),
    value: null,
    dangerColor: true,
    icon: deleteIcon
  };

  const territoryItems =
    territories.territoryRules?.map((rule) => {
      if (shouldReturnDates) {
        return {
          key: `${rule.territoryName} (${rule.territoryId})`,
          value: rule.ruleId.toString(),
          effectiveDate: rule.effectiveDate,
          endDate: rule.endDate
        };
      }
      return {
        key: `${rule.territoryName} (${rule.territoryId})`,
        value: rule.ruleId.toString()
      };
    }) ?? [];

  if (shouldIncludeUnassignItem) {
    territoryItems.unshift(unassignedTerritoryItem);
  }
  if (shouldIncludeDeleteItem) {
    territoryItems.unshift(deleteRedirectItem);
  }

  return territoryItems;
};

export const passesCollectionFilter = (
  filter: CollectionFilter<number>,
  idsToTest: ReadonlyMapOrSet<number> | undefined
): boolean => {
  switch (filter.kind) {
    case CollectionFilterKind.CONTAINS_ANY:
      if (!idsToTest) return false;
      return filter.ids.some((id) => idsToTest.has(id));
    case CollectionFilterKind.NOT_CONTAINS_ANY:
      if (!idsToTest) return true;
      return !filter.ids.some((id) => idsToTest.has(id));

    case CollectionFilterKind.EQUALS:
      if (!idsToTest) return false;
      return filter.ids.length === idsToTest.size && filter.ids.every((id) => idsToTest.has(id));
    case CollectionFilterKind.NOT_EQUALS:
      if (!idsToTest) return true;
      return filter.ids.length !== idsToTest.size || !filter.ids.every((id) => idsToTest.has(id));

    default:
      throw new Error(`Unsupported filter kind: ${filter.kind}`);
  }
};
export type ReadonlyMapOrSet<T> = ReadonlyMap<T, unknown> | ReadonlySet<T>;

export const groupIntoSources = (
  catalog: Iterable<{ country: string; sourceKey: string; sourceId: string; sourceUrl: string }>
): SourceGroup[] => {
  const sourceGroups = new Map<string, SourceGroup>();
  for (const { country, sourceKey, sourceId, sourceUrl } of catalog) {
    const group = sourceGroups.get(sourceKey) ?? { sourceKey, sourceId, sourceUrl, countries: [] };
    group.countries.push(country);
    sourceGroups.set(sourceKey, group);
  }
  return [...sourceGroups.values()];
};

export const determineTargetRuleComponent = ({
  targetHierarchyType,
  primaryHierarchy
}: {
  targetHierarchyType: HierarchyType;
  primaryHierarchy: PrimaryHierarchyTypeForMap;
}): TargetRuleComponent => {
  if (primaryHierarchy === targetHierarchyType) {
    return TargetRuleComponent.Dimension;
  }

  if (
    primaryHierarchy === HierarchyType.GeographicTerritoryHierarchy &&
    targetHierarchyType === HierarchyType.CustomerAccountHierarchy
  ) {
    return TargetRuleComponent.Modifier;
  }

  throw new Error(`Unexpected hierarchy types ${primaryHierarchy},${targetHierarchyType}`);
};

export const getViewFromMap = (map: Pick<MapboxMap, 'getCanvas' | 'getZoom' | 'unproject'> | null) => {
  if (!map) return null;
  // Can't rely on getBounds since we apply padding in the initialViewState
  // Hopefully fixed in mapbox gl js v3.2.0
  // https://github.com/mapbox/mapbox-gl-js/issues/11831
  const { width, height } = map.getCanvas();
  const bottomRight = map.unproject([width / window.devicePixelRatio, height / window.devicePixelRatio]);
  const topLeft = map.unproject([0, 0]);
  const richBounds = new LngLatBounds([topLeft.lng, bottomRight.lat, bottomRight.lng, topLeft.lat]);
  return {
    zoom: map.getZoom(),
    bounds: {
      west: richBounds.getWest(),
      north: richBounds.getNorth(),
      east: richBounds.getEast(),
      south: richBounds.getSouth()
    }
  };
};

export const getCoordinatesFromPoint = (interactiveFeature: GeoJSON.Feature<GeoJSON.Point>): [number, number] => {
  if (interactiveFeature.geometry.type !== 'Point')
    throw new Error(`Expected a Point feature, got ${interactiveFeature.type}`);
  const { coordinates } = interactiveFeature.geometry;
  if (coordinates.length < 2) throw new Error(`Point does not have lon,lat coordinates`);
  if (coordinates.length > 3) throw new Error(`Point has too many coordinates`);
  return coordinates as [number, number];
};

export const getClosestZoomBreakpoint = (zoomBreakpoints: number[], zoom: number) => {
  if (zoomBreakpoints.length === 0) throw new Error(`Cannot find closest zoom breakpoint when no breakpoints provided`);
  let closestBreakpoint = zoomBreakpoints[0];
  for (const breakpoint of zoomBreakpoints) {
    if (Math.abs(zoom - breakpoint) < Math.abs(zoom - closestBreakpoint)) {
      closestBreakpoint = breakpoint;
    }
  }
  return closestBreakpoint;
};
