import React, { useMemo } from 'react';

import { ArrowRight, Edit, OverflowMenuHorizontal, TrashCan } from '@carbon/icons-react';
import { Card, Classes, Text } from '@varicent/components';

import IconButton from 'components/Buttons/IconButton/IconButton';
import TextButton from 'components/Buttons/TextButton/TextButton';
import { Menu } from 'components/menu/Menu';
import { MenuItem } from 'components/menu/MenuItem';
import Popover from 'components/Popover/Popover';

import ExpandableCustomHierarchyTags from 'app/components/TerritoryMap/TerritoryMapGrid/ExpandableCustomHierarchyTags';

import { useDedicatedMapProvider } from 'app/contexts/dedicatedMapProvider';
import { useMapWorkerPostMessage } from 'app/contexts/mapWorkerContext';

import { SegmentFilterClause } from 'app/graphql/generated/graphqlApolloTypes';

import { CollectionFilter, CollectionFilterKind, NamedHierarchy } from 'app/models';

import block from 'utils/bem-css-modules';
import { formatMessage } from 'utils/messages/utils';

import style from './SegmentCard.module.pcss';

const b = block(style);

export interface SegmentCardProps {
  name: string;
  id: number;
  territoriesCount: number | null;
  isLoadingTerritoriesCount: boolean;
  filter: SegmentFilterClause[];
  customHierarchies: NamedHierarchy[];
}

const SegmentCard: React.FC<SegmentCardProps> = ({
  name,
  territoriesCount,
  id,
  filter,
  customHierarchies,
  isLoadingTerritoriesCount
}) => {
  const postMessage = useMapWorkerPostMessage();
  const { setSelectedSegmentId } = useDedicatedMapProvider();

  const formattedFilter: CollectionFilter<number> = useMemo(() => {
    return {
      ids: filter[0].hierarchies.map((hierarchy) => hierarchy.hierarchyId),
      kind: CollectionFilterKind[filter[0].operator]
    };
  }, [filter]);

  const applySegment = () => {
    setSelectedSegmentId(id);
    const newFilter = formattedFilter;
    postMessage({ type: 'custom-hierarchy-filter-change', filter: newFilter });
  };

  return (
    <Card className={b()} elevation={0}>
      <div className={b('cardContainer')}>
        <div className={b('header')}>
          <Text className={b('title')}>{name}</Text>
          <Popover
            minimal
            placement="bottom-end"
            content={
              <Menu data-testid="segment-menu-actions">
                <MenuItem icon={<Edit />} text={formatMessage('EDIT_SEGMENT')} data-testid="edit-segment-action" />
                <MenuItem
                  className={b('deleteActionButton')}
                  icon={<TrashCan />}
                  text={formatMessage('DELETE')}
                  data-testid="delete-segment-action"
                />
              </Menu>
            }
          >
            <IconButton type="button" testId="segment-card-action-button" icon={<OverflowMenuHorizontal />} />
          </Popover>
        </div>
        <div className={b('infoContainer')}>
          <div className={b('info')}>
            <div
              data-testid="territories-count"
              className={`${b('territoryCount')} ${isLoadingTerritoriesCount && Classes.SKELETON}`}
            >
              {formatMessage('TERRITORIES_COUNT', { count: territoriesCount ?? emDash })}
            </div>
            <div className={b('footer')}>
              <div className={b('tags')}>
                <ExpandableCustomHierarchyTags
                  customHierarchies={customHierarchies}
                  customHierarchyFilter={formattedFilter}
                />
              </div>
              <div className={b('applyButton')}>
                <TextButton
                  large={false}
                  rightIcon={<ArrowRight />}
                  minimal
                  text={formatMessage('APPLY')}
                  onClick={applySegment}
                  tooltipText={formatMessage('APPLY_SEGMENT')}
                  type="button"
                  testId="select-segment-button"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const emDash = `—`;

export default SegmentCard;
